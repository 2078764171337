<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable&&isOld"
              label="LBLREMOVE"
              icon="delete"
              @btnClicked="deleteInfo" />
            <c-btn
              v-show="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="jobStressBrainCardiovascular"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true"
              :editable="editable"
              label="제목"
              name="jobStressBrainCardiovascularName"
              v-model="jobStressBrainCardiovascular.jobStressBrainCardiovascularName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant 
              :required="true" 
              :editable="editable" 
              type="edit" 
              name="plantCd" 
              v-model="jobStressBrainCardiovascular.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <c-textarea
              :editable="editable"
              :rows="5"
              label="비고"
              name="remarks"
              v-model="jobStressBrainCardiovascular.remarks">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-upload 
              :attachInfo="attachJobStressInfo"
              :editable="editable">
            </c-upload>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-upload 
              :attachInfo="attachBrainCardiovascularInfo"
              :editable="editable">
            </c-upload>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'jobStressBrainCardiovascularDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaJobStressBrainCardiovascularId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      jobStressBrainCardiovascular: {
        heaJobStressBrainCardiovascularId: '',  // 직무스트레스&뇌심혈관계 결과 일련번호
        plantCd: '',  // 사업장코드
        jobStressBrainCardiovascularName: '',  // 제목
        remarks: '', // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      attachJobStressInfo: {
        isSubmit: '',
        taskClassCd: 'JOB_STRESS_RESULT',
        taskKey: '',
      },
      attachBrainCardiovascularInfo: {
        isSubmit: '',
        taskClassCd: 'BRAIN_CARDIOVASCULAR_RESULT',
        taskKey: '',
      },
      editable: true,
      isSave: false,
      detailUrl: '',
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.heaJobStressBrainCardiovascularId)
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.jbs.get.url
      this.saveUrl = transactionConfig.hea.jbs.update.url
      this.deleteUrl = transactionConfig.hea.jbs.delete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.heaJobStressBrainCardiovascularId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.jobStressBrainCardiovascular, _result.data)

          this.$set(this.attachJobStressInfo, 'taskKey', this.popupParam.heaJobStressBrainCardiovascularId)
          this.$set(this.attachBrainCardiovascularInfo, 'taskKey', this.popupParam.heaJobStressBrainCardiovascularId)

          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // nothing
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.hea.jbs.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.hea.jbs.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.jobStressBrainCardiovascular.regUserId = this.$store.getters.user.userId;
              this.jobStressBrainCardiovascular.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.heaJobStressBrainCardiovascularId) {
        this.$set(this.attachJobStressInfo, 'taskKey', result.data)
        this.$set(this.attachJobStressInfo, 'isSubmit', uid())
        this.$set(this.attachBrainCardiovascularInfo, 'taskKey', result.data)
        this.$set(this.attachBrainCardiovascularInfo, 'isSubmit', uid())
      }
      this.$set(this.popupParam, 'heaJobStressBrainCardiovascularId', result.data)
      this.getDetail();
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.heaJobStressBrainCardiovascularId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>